import { getSavePassword } from "@/api/auth/login"

export default {
    name: "modifyPassword",
    components: {

    },
    data: () => {
        return {
            email: localStorage.getItem("emailSoyego"),
            inputType: "password",
            passwordImg: false,
            passwordSwitch: true,
            passwordText: "",
            inputType1: "password",
            passwordImg1: false,
            passwordSwitch1: true,
            passwordText1: "",
        }
    },
    watch: {
        '$route': 'fetchData',
        passwordText: {
            deep: true,
            handler: function (val) {
                this.passwordText = val;
                this.fetchData();
            },
        },
        passwordText1: {
            deep: true,
            handler: function (val) {
                this.passwordText1 = val;
                this.fetchData();
            },
        },
    },
    created() {
    },
    mounted() {
        this.$forceUpdate();
    },
    computed: {
    },
    methods: {
        fetchData() {
            this.$forceUpdate();
        },
        passwordClick() {
            if (this.passwordImg) {
                this.inputType = "password"
            } else {
                this.inputType = "text"
            }
            this.passwordImg = !this.passwordImg
        },
        passwordClick1() {
            if (this.passwordImg1) {
                this.inputType1 = "password"
            } else {
                this.inputType1 = "text"
            }
            this.passwordImg1 = !this.passwordImg1
        },
        codeClick() {
            if(this.passwordText != this.passwordText1) {
                this.$message.warning("Your passwords do not match, please try again");
                return ;
            }
            let data = {
                email: this.email,
                code: localStorage.getItem("emailCodeSoyego"),
                key: localStorage.getItem("emailKeySoyego"),
                password: this.passwordText
            }
            var that = this;
            getSavePassword(data).then( res =>{
                if(res.code == 0){
                    this.$message.success("Password reset succeeded, please login again");
                    this.$router.push({ path: "/login" });
                }
            }).catch( err =>{
                if(err.code == -1){
                    that.$message.error("Incorrect dynamic code");
                    that.$router.go(-1);
                }
            })
        }
    }
}
