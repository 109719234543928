<template>
    <div class="modifyPassword">
        <div class="warp">
            <div class="main-sign">
                <h5>Modify Password</h5>
                <div>
                    <span>Password</span>
                    <input :type="inputType" v-model="passwordText" placeholder="Enter your password">
                    <img v-show="!passwordImg" @mousedown="passwordClick" src="@/assets/images/login/closeEyes.png">
                    <img v-show="passwordImg" @mousedown="passwordClick" src="@/assets/images/login/openEyes.png">
                </div>
                <div>
                    <span>Confirm Password</span>
                    <input :type="inputType1" v-model="passwordText1" placeholder="Please confirm your password">
                    <img v-show="!passwordImg1" @mousedown="passwordClick1" src="@/assets/images/login/closeEyes.png">
                    <img v-show="passwordImg1" @mousedown="passwordClick1" src="@/assets/images/login/openEyes.png">
                </div>
                <div class="btn">
                    <button class="btn-background" @click="codeClick">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.modifyPassword{
    width: 100%;
    padding-top: 30px;
    .warp{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        background: #fff;
        padding-bottom: 100px;
        display: flex;
        justify-content: center;
        
        .main-sign{
            width: 422px;
            display: flex;
            flex-direction: column;
            align-items: center;

            >h5{
                font-size: 24px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: bold;
                color: #330000;
                line-height: 28px;
                padding-top: 40px;
            }

            >div{
                padding-top: 50px;
                width: 100%;
                display: flex;
                flex-direction: column;
                position: relative;

                span{
                    font-size: 12px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #68696A;
                    padding-bottom: 15px;
                }
                input{
                    width: calc(100% - 20px);
                    outline: none;
                    border: none;
                    border-bottom: 1px solid #D8DADC;
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #A6AAAD;
                    padding-right: 20px;
                }

                >img{
                    position: absolute;
                    right: 0;
                    bottom: 5px;
                    cursor: pointer;
                    z-index: 10;
                }

                .code{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    cursor: pointer;
                    z-index: 10;
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #437EF5;
                }
            }

            .btn {
                padding-top: 60px;
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: row;

                button {
                    outline: none;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .btn-background {
                    width: 200px;
                    height: 54px;
                    background: #330000;
                    font-size: 18px;
                    font-family: Montserrat;
                    font-weight: bold;
                    color: #ffffff;
                }
            }
        }
    }
}
</style>

<script>
import modifyPassword from "./js/modifyPassword.js"

export default {
    name: "modifyPassword",
    components: {

    },
    mixins: [modifyPassword]
}
</script>